import {
  getAuth as getFirebaseAuth,
  connectAuthEmulator,
  Auth,
} from 'firebase/auth'
// import { useEffect, useState } from 'react'
// import { atom, useRecoilState } from 'recoil'
import { app } from '../app'

let auth: Auth
export function getAuth() {
  if (auth) {
    return auth
  }
  auth = getFirebaseAuth(app)

  if (process.env.NEXT_PUBLIC_USE_AUTH_EMULATOR) {
    connectAuthEmulator(auth, 'http://localhost:9091')
  }
  return auth
}
