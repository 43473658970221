import { initializeApp } from 'firebase/app'
import { isProduction } from '../../util/env'

export const previewConfig = {
  apiKey: 'AIzaSyANLoV6IUrPymRafoIah6nVT-ohGFPll_A',
  authDomain: 'manavino-preview.firebaseapp.com',
  projectId: 'manavino-preview',
  storageBucket: 'manavino-preview.appspot.com',
  messagingSenderId: '12226504720',
  appId: '1:12226504720:web:0bb3e0aa718fdaf5f28295',
}

export const productionConfig = {
  projectId: 'manavino-production',
  appId: '1:399180269093:web:815d69235bc9edc5e4a1cc',
  storageBucket: 'manavino-production.appspot.com',
  apiKey: 'AIzaSyAgX3-3ENgaFlsy6palZoZoSkKXVd_nyFw',
  authDomain: 'manavino-production.firebaseapp.com',
  messagingSenderId: '399180269093',
  measurementId: 'G-J8VJWGTY83',
}

export const app = initializeApp(
  isProduction() ? productionConfig : previewConfig
)
